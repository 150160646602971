import { MessageBlockArray } from "src/app/shared/utilities/types";

export const quotaDisclaimer: MessageBlockArray = [
  {
    heading: "",
    content:
      "Every account is allotted two changes for License Plate/VIN Edits or Vehicle Swaps within a rolling 365-day window. For example if an update is used on Jan 1, 2022, the update will be renewed on Jan 2, 2023.",
  },
  {
    heading: "",
    content:
      "Updates are consumed each time one of the following actions occur:  ",
  },
  {
    heading: "",
    content: "• A vehicle is swapped from one vehicle to another.",
  },
  {
    heading: "",
    content: "• A license plate or VIN is edited on a membership vehicle.",
  },
  {
    heading: "",
    content:
      "We do not count Plate/VIN Edits for non-membership vehicles against the alloted change.",
  },
] as MessageBlockArray;

export const verifyWithQRCodeText: MessageBlockArray = [
  {
    heading: "Why do I need to verify my vehicle?",
    content:
      "We take privacy and safety surrounding your personal data seriously. Car wash details are excluded from the order history until the vehicle is verified. Verifying each of the cars with you directly the first time you bring them in to get washed after signing up provides you the best security possible.",
  },
  {
    heading: "How do I verify my vehicle?",
    content:
      "Next time you're onsite at one of our locations with your vehicle, inform the cashier you would like your vehicle verified and show your QR code. The QR code can be found in Vehicles -> Menu -> Vehicle Verification.",
  },
] as MessageBlockArray;

export const allowLocationPermissionText =
  "It looks like you haven't shared your location with myQQ. Please share your location in settings to enable myQQ to show your position on the map.";

export function faq(loginLink) {
  return [
    {
      question: "What is an Unlimited Membership?",
      answer:
        "An unlimited membership lets you wash your car as often as you like at any Quick Quack location. You can purchase an unlimited car wash membership for any of our three wash packages. An unlimited car wash membership provides great value and is an easy way to ensure that you Don't Drive Dirty®.",
    },
    {
      question: "How do I become a Member?",
      answer:
        "To purchase a membership, select your preferred wash package. From there, visit myQQ to sign up. It only takes a few minutes to register and create an account. Then add your license plate number or VIN so your car can be quickly identified when you visit any Quick Quack. You can also sign up at any Quick Quack Car Wash location.",
    },
    {
      question: "Can I add another vehicle to my membership?",
      answer: `You can add up to four vehicles to any unlimited car wash subscription–so everyone in your flock can drive happy in a clean car. You can add more cars to your plan by <a href=${loginLink}> signing in  </a> to your myqq account, or at any Quick Quack location.`,
    },
    {
      question: "Can I change my membership plan?",
      answer: `Yes. You can change your membership plan in  <a href=${loginLink}>myqq</a> or asking a car wash Team Member to submit a request for you.`,
    },
    {
      question: "Can I cancel my car wash membership?",
      answer: `Yes. You can cancel your membership at any time in <a href=${loginLink}> myqq </a>, select “Account” => "Settings" => "Cancel Your Membership". You can also call us at <strong>888-772-2792</strong> and a Customer Care Representative can assist you. Since you’ll already have paid for the current pay period, your membership will stay active through the end of the current period. No further payments will be drawn.`,
    },
  ];
}

export const washfaq = [
  {
    question: "How does the car wash work?",
    answer:
      "Going through the car wash is fast and easy. A Team Member will check you in and guide you into the car wash conveyor. Once in position, you’ll put your car in neutral, take your foot off the brake and hands off the wheel. Your car will be guided on our conveyor through multiple stages of treatments, rinses and coatings to get your car cleaned, shined and dried in 3 minutes or less.",
  },
  {
    question: "Will the wash damage my vehicle?",
    answer:
      "No. We use a brushless system that is effective at removing dirt. Our spinning wraps are made with hand-cut cloth strips that are gentle on your cars surface. And we use eco-friendly, pH balanced soaps to get your car clean without harming the paint.",
  },
  {
    question: "Is my vehicle too big to go through the wash?",
    answer:
      "Quick Quack Car Wash accepts all kinds of vehicles through the wash as long as they don’t exceed 7’2” or have a tire width wider than 13.5”. Things that cannot go through the wash include: <ul class='list'> <li> Dually trucks </li> <li>Vehicles with bike racks attached </li> <li> Non-standard hitches (some locations cannot accept any hitches) </li> <li> Trucks with beds that are not completely empty </li> <li> Vehicles that are covered in hardened mud that breaks off in chunks </li> <li> Storage pods on top of vehicle Ladder racks </li> </ul> This list is not all-inclusive. If you have any concerns, a Team Member can inspect your vehicle before going into the wash to ensure all requirements are met.",
  },
  {
    question: "What is the 100% Clean Guarantee?",
    answer:
      "If you are not 100% satisfied with your wash, we will immediately rewash your car. If it rains after your car wash, you can bring it back within 48 hours for a free wash. Of course, members can wash as much as they like.",
  },
  {
    question: "Can I clean the inside of my vehicle?",
    answer:
      "We offer free dash wipes and vacuums at nearly every location to help you keep the inside of your car clean, so you Don’t Drive Dirty®.",
  },
];

export const generalcarwashfaq = [
  {
    question: "How important is a car wash?",
    answer:
      "A car wash in important to help remove harmful sap, pollen and prevent rust corrosion. And at Quick Quack, we make washing your car easy and affordable. In three minutes or less you can get cleaned, dried and shined without breaking the bank.",
  },
  {
    question: "How often do you get your car washed?",
    answer:
      "For routine car washing, most experts recommend washing your car every two weeks. However, if you drive more often, or your car gets covered in bugs, sap, pollen, road salt or salt water, it is recommended to wash as soon as possible to remove the elements safely without harming your vehicle’s paint. With an unlimited wash plan, you can wash as often as you want, for one monthly convenient price.",
  },
  {
    question: "Do car washes close in the winter?",
    answer:
      "Quick Quack Car Wash is open rain or shine. We will only close if the weather becomes unsafe for customers, members and our Team Members. You can follow us on social media for store updates.",
  },
  {
    question:
      "Is it pointless to wash a car if it is going to rain the same day?",
    answer:
      "Our water repellent helps rainwater bead up and glide more easily off of your vehicle and windshield. This helps with overall visibility, making your commute in the rain safer overall. Plus, if it rains the same day you wash, you can rewash within 48 hours at any Quick Quack free of charge.",
  },
  {
    question: "Is it better to wash your car during the day or at night?",
    answer:
      "When visiting Quick Quack, the best time to wash your car is whenever it fits into your schedule. And with most location open from 7 a.m. to 8 p.m., you can wash morning or night.",
  },
  {
    question: "Is it bad to wash a car with just water?",
    answer:
      "Using water alone will not get your car clean. At Quick Quack, we use bug blaster and eco-friendly soaps to remove harmful dirt and grease to get your car truly clean. And our duck wax and premium Ceramic Duck wash helps protect your paint from the elements while illuminating shine.",
  },
];
